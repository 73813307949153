@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans text-gray-800;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

h1 {
  @apply font-bold text-5xl text-gray-800;
  @apply mb-4;
}

h2 {
  @apply font-semibold text-3xl text-gray-900;
}

h1 + h2 {
  @apply mt-3;
}

h3 {
  @apply font-bold text-xl text-gray-800;
}

h2 + h3 {
  @apply mt-2;
}

h4 {
  @apply mt-2;
  @apply font-semibold text-lg text-gray-800;
}

button,
a.btn {
  @apply bg-indigo-600 rounded shadow;
  @apply border-4 border-transparent;
  @apply px-4 py-2;
  @apply font-semibold text-white;
  &:hover:not(:disabled) {
    @apply bg-indigo-700;
  }
  &:disabled {
    @apply bg-indigo-400;
  }
  &:focus {
    @apply outline-none border-4 border-blue-300;
  }
}

button.secondary,
a.btn.secondary {
  @apply bg-yellow-500 text-black;
}

a.btn {
  @apply inline-block;
}

a:not(.btn) {
  @apply text-blue-600 font-semibold;
  &:hover {
    @apply text-blue-800;
  }
}

.bg-transition {
  transition: background-color ease-out 200ms;
}

.btn {
  @apply bg-blue-100 rounded px-4 py-2;
}

pre {
  @apply bg-gray-100 mb-4 p-3 rounded;
}

code {
  @apply bg-gray-100 text-gray-700 text-sm rounded;
  padding: 0.2rem 0.4rem;
}

.content {
  @apply text-gray-700;
  @apply leading-relaxed;
  ol,
  ul {
    @apply pl-4;
    list-style: disc inside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }
}
